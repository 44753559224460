<template>
    <div>
        <quillEditor @change="test" v-model="content" ref="myQuillEditor" :options="quillOption">
        </quillEditor>
        <!-- 文件上传input 将它隐藏-->
        <el-upload class="upload-demo" :action="qnLocation" :http-request='beforeUpload' :data="uploadData"
            :on-success='upScuccess' ref="upload" style="display:none">
            <el-button size="small" type="primary" id="imgInput" v-loading.fullscreen.lock="fullscreenLoading"
                element-loading-text="插入中,请稍候">点击上传</el-button>
        </el-upload>
    </div>
</template>
<script>
    import { quillEditor } from "vue-quill-editor";
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import * as Quill from 'quill'    // 引入编辑器
    import quillConfig from "../quill-config.js"
    export default {
        props: ['desc', 'ys'],
        components: {
            quillEditor
        },
        data() {
            return {
                editorOption: {
                    placeholder: '编辑文章内容'
                },
                addRange: [],
                uploadData: {},
                photoUrl: '', // 上传图片地址
                uploadType: '', // 上传的文件类型（图片、视频）
                fullscreenLoading: false,
                qnLocation: 'http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php',
                $refs: {
                    myQuillEditor: HTMLInputElement,
                    imgInput: HTMLInputElement
                },
                content: '',
                descs: '',
                quillOption: quillConfig,
            }
        },
        watch: {
            desc() { // 接收父组件传来的参数名
                this.getmain();
            }
        },
        mounted() {
            // 为图片ICON绑定事件  getModule 为编辑器的内部属性
            this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('image', this.imgHandler)
            this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('video', this.videoHandler) // 为视频ICON绑定事件
            quillConfig.initButton();
        },
        methods: {
            getmain() {
                this.$nextTick(function () {
                    this.content = this.htmlDecode(this.desc);
                })
            },
            test() {

                var regex1 = new RegExp("(i?)(\<img)(?!(.*?style=['\"](.*)['\"])[^\>]+\>)", 'gmi')
                var htmlstr = this.content.replace(regex1, '$2 style=""$3');
                var regex2 = new RegExp("(i?)(\<img.*?style=['\"])([^\>]+\>)", 'gmi')
                htmlstr = htmlstr.replace(regex2, '$2width:100%;text-align:center;$3')

                this.descs = this.ys;
                this.$emit('fathertest', { a: this.htmlEncode(htmlstr), b: this.descs })
            },
            htmlEncode: function (html) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
                temp.textContent != undefined
                    ? (temp.textContent = html)
                    : (temp.innerText = html);
                //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
                var output = temp.innerHTML;
                temp = null;
                return output;
            },
            /*2.用浏览器内部转换器实现html解码（反转义）*/
            htmlDecode: function (text) {
                //1.首先动态创建一个容器标签元素，如DIV
                var temp = document.createElement("div");
                //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
                temp.innerHTML = text;
                //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
                var output = temp.innerText || temp.textContent;
                temp = null;
                return output;
            },
            // 图片上传之前调取的函数
            // 这个钩子还支持 promise
            beforeUpload(file) {
                return this.qnUpload(file)
            },
            // 图片上传前获得数据token数据
            qnUpload(file) {
                this.fullscreenLoading = true
                // const suffix = file.name.split('.')
                // const ext = suffix.splice(suffix.length - 1, 1)[0]
                if (this.uploadType === 'image') { // 如果是点击插入图片
                    let formData = new FormData();
                    formData.append("file", file.file); // 'file[]' 代表数组 其中`file`是可变的
                    return this.axios.post("http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php", formData)
                        .then((res) => {
                            if (res.data.code == 0) {
                                // console.log(res);
                                return res.data;
                                this.uploadData = res.data
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    // TODO 图片格式/大小限制
                    //   return this.$http('qiniu/examples/upload_simple_file.php').then(res => {
                    //     this.uploadData = {
                    //       key: `image/${suffix.join('.')}_${new Date().getTime()}.${ext}`,
                    //       token: res.data
                    //     }
                    //   })
                } else if (this.uploadType === 'video') { // 如果是点击插入视频
                    let formData = new FormData();
                    formData.append("file", file.file); // 'file[]' 代表数组 其中`file`是可变的
                    return this.axios.post("http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php", formData)
                        .then((res) => {
                            if (res.data.code == 0) {
                                console.log(res);
                                return res.data;
                                this.uploadData = res.data
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    // return this.$http('qiniu/examples/upload_simple_file.php').then(res => {
                    //     this.uploadData = {
                    //         key: `video/${suffix.join('.')}_${new Date().getTime()}.${ext}`,
                    //         token: res
                    //     }
                    // })
                }
            },
            // 图片上传成功回调   插入到编辑器中
            upScuccess(e, file, fileList) {
                this.fullscreenLoading = false
                let vm = this
                let url = ''
                if (this.uploadType === 'image') { // 获得文件上传后的URL地址
                    //   url = STATICDOMAIN + e.key
                    url = e.file
                } else if (this.uploadType === 'video') {
                    // url = STATVIDEO + e.key
                    url = e.file
                }
                if (url != null && url.length > 0) { // 将文件上传后的URL地址插入到编辑器文本中
                    let value = url
                    // API: https://segmentfault.com/q/1010000008951906
                    // this.$refs.myTextEditor.quillEditor.getSelection();
                    // 获取光标位置对象，里面有两个属性，一个是index 还有 一个length，这里要用range.index，即当前光标之前的内容长度，然后再利用 insertEmbed(length, 'image', imageUrl)，插入图片即可。
                    vm.addRange = vm.$refs.myQuillEditor.quill.getSelection()
                    value = value.indexOf('http') !== -1 ? value : 'http:' + value
                    vm.$refs.myQuillEditor.quill.insertEmbed(vm.addRange !== null ? vm.addRange.index : 0, vm.uploadType, value, Quill.sources.USER) // 调用编辑器的 insertEmbed 方法，插入URL
                } else {
                    this.$message.error(`${vm.uploadType}插入失败`)
                }
                this.$refs['upload'].clearFiles() // 插入成功后清除input的内容
            },
            // 点击图片ICON触发事件
            imgHandler(state) {
                this.addRange = this.$refs.myQuillEditor.quill.getSelection()
                if (state) {
                    let fileInput = document.getElementById('imgInput')
                    fileInput.click() // 加一个触发事件
                }
                this.uploadType = 'image'
            },
            // 点击视频ICON触发事件
            videoHandler(state) {
                this.addRange = this.$refs.myQuillEditor.quill.getSelection()
                if (state) {
                    let fileInput = document.getElementById('imgInput')
                    fileInput.click() // 加一个触发事件
                }
                this.uploadType = 'video'
            },
        },
    }
</script>
<style>

</style>